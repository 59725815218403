import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import {EXDefaultImage} from "components/utils/DefaultImage.js.erb"
import Skeleton from '@material-ui/lab/Skeleton'

function HomeImage(props) {
  const [imageSRC, setImage] = useState("")
  const [loaded, setLoaded] = useState(false)
  const [loadClass, setLoadClass] = useState("hidden")
  const [hasError, setHasError] = useState(false)
  
  useEffect(() => {
    if (props.image_url) {
      setImage(props.image_url)
      setHasError(false)
      setLoaded(false)
    }
  }, [props.image_url])

  function onError() {
    setImage(EXDefaultImage)
    setLoaded(true)
    setLoadClass("")
    setHasError(true)
  }

  function onLoad() {
    setLoadClass("")
    setLoaded(true)
  }

  if (!props.image_url) {
    return null
  }
  
  return (
    <figure className={props.home_class}>
      <picture style={{ display: 'block', borderRadius: 'inherit' }}>
        {!hasError && props.image_url.endsWith('webp') && (
          <source srcSet={props.image_url} type="image/webp" />
        )}
        <img 
          className={`lazyload ${loadClass}`}
          alt={props.alt || ""}
          src={imageSRC}
          onLoad={onLoad}
          onError={onError}
          style={{ 
            borderRadius: 'inherit',
            maxWidth: '100%',
            height: 'auto'
          }}
        />
      </picture>
      {!loaded && (
        <Skeleton 
          variant="rect" 
          className="lazyload" 
          height={props.height || 188}
          animation={false} 
        />
      )}
    </figure>
  )
}

HomeImage.propTypes = {
  home_class: PropTypes.string,
  image_url: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.number
}

HomeImage.defaultProps = {
  home_class: '',
  image_url: '',
  alt: '',
  height: 188
}

export default HomeImage

