import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import { ThemeProvider } from '@material-ui/styles';
import themeViewnia from "components/utils/Theme";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from '@material-ui/lab/Skeleton';

// Import Swiper styles
//import 'swiper/swiper.scss';
//import 'swiper/components/navigation/navigation.scss';
//import 'swiper/components/pagination/pagination.scss';
//import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel]);
import HomeImage from "components/home/HomeImage";
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: "20%",
    },
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));

function CarouselRegion(props){
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  useEffect(() => {
    get_regions();
  }, []);

  function get_regions(){
    setLoading(true)
    return fetch(`/get_regions.json`)
      .then(response => response.json())
      .then(json => {
        setRegions(json.regions)
        setLoading(false)
      }
      )
      .catch(error => console.log(error));
  }
  
  
  return (
    <ThemeProvider theme={themeViewnia}>

        <section className="caroussel">
            <section className="products-section">
            {loading && 
                <section id="region-loader" className={classes.root}>     

                  <Skeleton variant="rect" className="region-loader" height={390} animation="false"/>
                  <Skeleton variant="rect" className="region-loader" height={390} animation="false"/>
                  <Skeleton variant="rect" className="region-loader" height={390} animation="false"/>
                  <Skeleton variant="rect" className="region-loader" height={390} animation="false"/>
                  <Skeleton variant="rect" className="region-loader" height={390} animation="false"/>
                  <Skeleton variant="rect" className="region-loader" height={390} animation="false"/>
                </section> ||
                <>
                <div className='carouselregion'>
                  <div className="swiper-button-prev" ref={navigationPrevRef}></div>
                  <div className="swiper-button-next" ref={navigationNextRef}></div>
                </div>
                <Swiper
                navigation
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log('swiper')}
                mousewheel={true}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) =>
                {       swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                }}

                breakpoints={{
                  "375": {
                    "slidesPerView": 1.2,
                    "spaceBetween": 16
                  },
                  "425": {
                    "slidesPerView": 1.2,
                    "spaceBetween": 16
                  },
                  "768": {
                    "slidesPerView": 2.2,
                    "spaceBetween": 16
                  },
                  "1024": {
                    "slidesPerView": 4,
                    "spaceBetween": 16
                  },
                  "1440": {
                    "slidesPerView": 6,
                    "spaceBetween": 16
                  }
                }}
                >
                    {regions && regions.some(region => region.excursions_numbers > 0) && (
                    regions.map((region) => {
                        const labelId = `regions-label-${region.id}`;
                        if (region.excursions_numbers > 0) { // Verifica que excursion_numbers sea mayor que cero
                            return (
                                <SwiperSlide key={labelId}>
                                    <a href={`/products?region=${region.id}`} className="product-region-image flex-center" target="_blank" title="View Details">

                                        <HomeImage
                                            image_url={region.large}
                                            url={undefined}
                                            alt={region.description}
                                            height={390}
                                            home_class={"region-figure"}
                                        />
                                        <div className="flex-center-absolute"> 
                                            <h3 className="product-white-label">{region.name}</h3>
                                            <h4 className="product-region-numbers">{region.excursions_numbers} + {i18n.t('home.excursions')}</h4>    
                                        </div>
                                    </a>
                                </SwiperSlide>
                            );
                        } else {
                            return null; // Retorna null si excursion_numbers es 0
                        }
                    })
                )}
                
                </Swiper>
                </>
                }

           
            </section>
        </section>

    </ThemeProvider>
  );
}

export default CarouselRegion
