import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import 'photoswipe/dist/photoswipe.css'
import { makeStyles } from '@material-ui/core/styles';
// Include Lightbox 
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import { set } from 'date-fns'
import { da } from 'date-fns/locale'
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: "20%",
  },
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  dropdownStyle: 
  {
    border: "1px solid #9b9b9b",
    "& ul": {
      backgroundColor: "#fff",
    },
    "& li": {
        fontSize: "1em",
    },
  },
}));


function MyGallery(props){
  const [images, setImages] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  let lightbox = null;
  const classes = useStyles();
  useEffect(() => {
      setImages(props.excursion_images)
      setDataSource(props.excursion_images.map((image) => {
        return {
          src: image.original_url,
          width: image.image_width,
          height: image.image_height,
          alt: image.alt || image.custom_alt
        }
      }

      ))
      
  }, [props.excursion_images]);

  useEffect(() => {
    if(dataSource.length === 0){
      return
    }
    lightbox = new PhotoSwipeLightbox({
      // may select multiple "galleries"
    
      // Elements within gallery (slides)
      mouseMovePan: true,

      initialZoomLevel: 'fit',
      secondaryZoomLevel: 1.5,
      maxZoomLevel: 1,
      dataSource: dataSource,
      // setup PhotoSwipe Core dynamic import
      pswpModule: () => import('photoswipe/dist/photoswipe.esm.js')
    });
    lightbox.init();
  
    
}, [dataSource]);
return (
  <section>
    { images.length > 0 && (
      <div className="single-slider product-photo-gallery">
        <div className="single-slider-gallery">
        <div className="row" id="gallery-big">
          <div className="col-xs-12 col-sm-12 col-md-8" id="big-gallery">
            <a href="#" onClick={() => lightbox.loadAndOpen(0)}>
              <div className='product-image-container skeleton product-image-container--first' style={{ backgroundImage: `url(${images[0].original_url})`}} alt={images[0].alt || images[0].custom_alt} />
            </a>
          </div>
          <div className="col-md-4" id="small-gallery">
            <div className="row" id="gallery-small">  
              {images.slice(1, 5).map((image, index) => (
                <div className="hidden-md-down col-md-6 product-image-col">
                  <a href="#" onClick={() => lightbox.loadAndOpen(index + 1)}>
                    <div className='product-image-container skeleton product-image-container--second' style={{ backgroundImage: `url(${image.large_url})`}} alt={image.alt || image.custom_alt} />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
         <button className="btn btn-images" onClick={() => lightbox.loadAndOpen(0)}>{i18n.t('products_show_photos', { n: images.length })}</button>
      </div>
      </div>
    )
    ||
      <section id="photos-loader" className={classes.root}>     
        <Grid className='single-slider' container spacing={2}>
           <Grid key={"skeleton-big-photo"} item xs={12} sm={6} md={8} className='posts'>
              <Skeleton variant="rect" className="lazyload" height={(600)} />
            </Grid>
            <Grid key={"skeleton-mini-photo"} item xs={12} sm={6} md={4} className='posts'>
              <Skeleton variant="rect" className="lazyload"  style={{ marginBottom: '10px' }} height={(193)} />
              <Skeleton variant="rect" className="lazyload"  style={{ marginBottom: '10px' }} height={(193)} />
              <Skeleton variant="rect" className="lazyload"  style={{ marginBottom: '10px' }} height={(193)} />
            </Grid>
        </Grid>
      </section>
    }
  </section>
);
}

export default MyGallery