import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import { ThemeProvider } from '@material-ui/styles';
import themeViewnia from "components/utils/Theme";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from 'swiper';
import Rating from '@material-ui/lab/Rating';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from '@material-ui/lab/Skeleton';
import ProductSlim from "components/products/ProductSlim";

// Import Swiper styles
//import 'swiper/swiper.scss';
//import 'swiper/components/navigation/navigation.scss';
//import 'swiper/components/pagination/pagination.scss';
//import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel]);

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: "20%",
    },
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));

function CarouselRecommended(props){
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  useEffect(() => {
    setLoading(true)
    setProducts(props.products)
    setLoading(false)
  }, [props.products]);

  const handleImageError = (e) => {
    e.target.onerror = null; // Evita bucles infinitos en caso de error
  };
  
  return (
    <ThemeProvider theme={themeViewnia}>
    {!props.home_collection_id && (
            <section className="caroussel">
                <section className="products-section">
                    {loading ? (
                        <section
                            id="skeletons-loader"
                            className={classes.root}
                        >
                            <Skeleton
                                variant="rect"
                                className="region-loader"
                                width={424}
                                height={275}
                            />
                            <Skeleton
                                variant="rect"
                                className="region-loader"
                                width={424}
                                height={275}
                            />
                            <Skeleton
                                variant="rect"
                                className="region-loader"
                                width={424}
                                height={275}
                            />
                            <Skeleton
                                variant="rect"
                                className="region-loader"
                                width={424}
                                height={275}
                            />
                            <Skeleton
                                variant="rect"
                                className="region-loader"
                                width={424}
                                height={275}
                            />
                        </section>
                    ) : (
                        <>
                                <div
                                    className="swiper-button-prev"
                                    ref={navigationPrevRef}
                                ></div>
                            <div
                                className="swiper-button-next"
                                ref={navigationNextRef}
                            ></div>
                            <Swiper
                                mousewheel={true}
                                onSlideChange={() =>
                                    console.log("slide change")
                                }
                                onSwiper={(swiper) =>
                                    console.log("swiper")
                                }
                                navigation={{
                                    prevEl: navigationPrevRef.current,
                                    nextEl: navigationNextRef.current,
                                }}
                                onBeforeInit={(swiper) => {
                                    swiper.params.navigation.prevEl =
                                        navigationPrevRef.current;
                                    swiper.params.navigation.nextEl =
                                        navigationNextRef.current;
                                }}
                                breakpoints={{
                                    
                                    "375": {
                                        slidesPerView: 1.2,
                                        spaceBetween: 16,
                                    },
                                    "425": {
                                        slidesPerView: 2.2,
                                        spaceBetween: 16,
                                    },
                                    "768": {
                                        slidesPerView: 3,
                                        spaceBetween: 16,
                                    },
                                    "1024": {
                                        slidesPerView: 5,
                                        spaceBetween: 16,
                                    },
                                    "1440": {
                                        slidesPerView: 5,
                                        spaceBetween: 16,
                                    },
                                }}
                            >
                                {products &&
                                    products.map((product) => {
                                        const labelId = `products-label-${product.id}`;
                                        return (
                                            <SwiperSlide key={labelId}>
                                                    <ProductSlim
                                                        key={labelId}
                                                        product={product}
                                                        image_format={"large"}
                                                    />
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </>
                    )}
                </section>
        </section>
    )}
</ThemeProvider>
  );
}

export default CarouselRecommended
