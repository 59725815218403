import React, { useState, useEffect, useRef } from 'react'
import i18n from 'i18n-js'
import { ThemeProvider } from '@material-ui/styles';
import themeViewnia from "components/utils/Theme";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Product from "components/products/Product";
import Radio from '@material-ui/core/Radio';
import Skeleton from '@material-ui/lab/Skeleton';
import {mkt_money} from "components/utils/Money.jsx";
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-W6W9H6NJ'
}
TagManager.initialize(tagManagerArgs)


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: "20%",
  },
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  dropdownStyle: 
  {
    border: "1px solid #9b9b9b",
    "& ul": {
      backgroundColor: "#fff",
    },
    "& li": {
        fontSize: "1em",
    },
  },
  titleContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    '& h2': {
      margin: 0,
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    '& .MuiButton-root.product-clear': {
      marginLeft: 'auto',
    },
    '& .MuiSkeleton-root': {
      margin: 0,
    },
  },
  skeletonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));



function Products(props){
  const pageRef = useRef(1); 
  const newPage = useRef(1); 
  const [products, setProducts] = useState([]);
  const [optional_products, setOptionalProducts] = useState([]);
  const [optinal_results_text, setOptinalResultsText] = useState("");
  const [optinal_results, setOptinalResults] = useState(false);
  
  
  const [activities, setActivities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPageloading, setIsPageloading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  
  const [activityValue, setActivityValue] = useState("");
  const [regionValue, setRegionValue] = useState("");
  const [timeValue, setTimeValue] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [dateValueForSelect, setDateValueForSelect] = useState("");
  const [isSearch, setIsSearch] = useState(false)
  const [activityObject, setActivityObject] = useState("");
  const [regionObject, setRegionObject] = useState("");
  
  const [stringSearch, setStringSearch] = useState("");
  const [titleSearch, setTitleSearch] = useState("");
  const [descriptionCategory, setDescriptionCategory] = useState(false);
  

  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDateValue(date);
    setDateValueForSelect("current_date");
  };

  const [initialTitle, setInitialTitle] = useState(i18n.t("products_search.all_adventures"));
  
  const [loadingTitle, setLoadingTitle] = useState(true);
  
  const classes = useStyles();

 
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let activity = ""
    let region = ""
    let time = ""
    let date = ""
    console.log("actividad: ", props.activity)
    if (urlParams.has('activity')){
      activity = (urlParams.get('activity'))
      setActivityValue(urlParams.get('activity'))
    }
    if(props.activity){
      activity = props.activity
      setActivityValue(props.activity)
    }
    if (urlParams.has('region')){
      region = (urlParams.get('region'))
      setRegionValue(urlParams.get('region'))
    }
    if (urlParams.has('time')){
      time = (urlParams.get('time'))
      setTimeValue(urlParams.get('time'))
    }
    if (urlParams.has('date')){
      date = (new Date(Date.parse(urlParams.get('date'))))
      setDateValue(date)
      setSelectedDate(date);
      setDateValueForSelect("current_date")
    }

    setTimeout(() => {
      if ((urlParams.has('activity') && isNaN(urlParams.get('activity'))) || 
          props.activity || 
          (urlParams.has('region') && isNaN(urlParams.get('region'))) || 
          urlParams.has('time') || 
          urlParams.has('date')) {
        let titleParts = [];
        if (urlParams.has('activity') && isNaN(urlParams.get('activity'))) {
          titleParts.push(props.activity || urlParams.get('activity'));
        }
        if (urlParams.has('region') && isNaN(urlParams.get('region'))) {
          titleParts.push(urlParams.get('region'));
        }
        if (urlParams.has('time')) {
          titleParts.push(i18n.t("excursions." + urlParams.get('time')));
        }
        if (urlParams.has('date')) {
          titleParts.push(new Date(Date.parse(urlParams.get('date'))).toLocaleDateString());
        }
        if (titleParts.length > 0) {
          setInitialTitle(titleParts.join(" " + i18n.t("products_search.in") + " "));
        }
      }
      setLoadingTitle(false);
    }, 1000);

    get_products(activity, region, time, date);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if ((window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200) && !isPageloading ) {
        if (!isPageloading && hasMore) {
          if (pageRef.current !== newPage.current + 1) {
            pageRef.current = newPage.current + 1;
            get_products(activityValue, regionValue, timeValue, dateValue, true);
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isPageloading]);

  useEffect(() => {
    if(activityValue){
      setActivityObject(activities.find(activity => activity.id ==  activityValue))
    }
    if(regionValue){
      setRegionObject(regions.find(region => region.id ==  regionValue))
    }
  }, [activityValue, regionValue]);

  function search(){
    setIsSearch(true)
    newPage.current = 1
    pageRef.current = 1
    get_products(activityValue, regionValue, timeValue, dateValue);
  }

  useEffect(() => {
    if(isSearch){
      get_products(activityValue, regionValue, timeValue, dateValue);
    }
  }, [isSearch]);


  function clear(){
    setActivityValue("");
    setRegionValue("");
    setTimeValue("");
    setDateValue("");
    setActivityObject("");
    setRegionObject("");
    setStringSearch("");
    setSelectedDate("");
    get_products("", "", "", "");
    setIsSearch(false)
  }
  
  function get_products(activityValue_t = null, regionValue_t = null, timeValue_t= null, dateValue_t= null, isNewPage = false){
    (!isNewPage && setLoading(true) || setIsPageloading(true))
    const nextPage = (isNewPage ? newPage.current + 1 : 1) //Aumenta solo si es scroll infinito

    return fetch(`/get_products.json?activity=${activityValue_t}&region=${regionValue_t}&time=${timeValue_t}&date=${dateValue_t}&page=${(nextPage)}
    `)
      .then(response => response.json())
      .then(json => {
        let updatedProducts = isNewPage ? [...products, ...json.excursions] : json.excursions;
        let uniqueProducts = Array.from(new Map(updatedProducts.map(p => [p.id, p])).values());
        setProducts(uniqueProducts); 
        createDataLayer(uniqueProducts);
        setActivities(json.activities)
        setRegions(json.regions)
        setLoading(false)
        setIsPageloading(false)
        if (isNewPage) {
          setPage(nextPage); //Solo incrementa si es scroll
          newPage.current = nextPage
        } else {
          setPage(1); //Reinicia en búsquedas manuales
          newPage.current = 1
        }
        setHasMore(json.has_more)
        let activitiesObj = {}
        let regionsObj = {}
        
        if(activityValue_t){
          activitiesObj = (json.activities.find(activity => activity.id ==  activityValue_t))
        }
        if(regionValue_t){
          regionsObj = (json.regions.find(region => region.id ==  regionValue_t))
        }

        if(uniqueProducts.length > 0){
          setDescriptionCategory((activitiesObj && activitiesObj.description) ? activitiesObj.description : i18n.t("products_search.subtext"))
          setStringSearch([(activitiesObj ? activitiesObj.name : ""), (regionsObj ? regionsObj.name : ""), (timeValue_t ? i18n.t("excursions." + timeValue_t) : ""), (dateValue_t ? dateValue_t.toLocaleDateString() : "")].filter(Boolean).join(", "))
        }else{
          if(regionsObj.name  && activitiesObj.name ){
            setDescriptionCategory((activitiesObj && activitiesObj.description) ? activitiesObj.description : i18n.t("products_search.subtext"))
            setStringSearch(i18n.t('searches.category_products_in_zone_no_result', {category_name: (activitiesObj ? activitiesObj.name : ""), zone_name: (regionsObj ? regionsObj.name : "")}))
          }else if(regionsObj.name ){
            setStringSearch(i18n.t('searches.all_products_in_zone_no_result', {zone_name: (regionsObj ? regionsObj.name : "")}))
          }else if(activitiesObj.name ){
            setDescriptionCategory((activitiesObj && activitiesObj.description) ? activitiesObj.description : i18n.t("products_search.subtext"))
            setStringSearch(i18n.t('searches.all_category_products_no_result', {category_name: (activitiesObj ? activitiesObj.name : "")}))
          }
        }
        setTitleSearch([(activitiesObj ? activitiesObj.name : ""), (regionsObj ? regionsObj.name : ""), (timeValue_t ? i18n.t("excursions." + timeValue_t) : ""), (dateValue_t ? dateValue_t.toLocaleDateString() : "")].filter(Boolean).join(" " + i18n.t("products_search.in") + " "));

        
        if(json.no_results_found){     
          if(json.optinal_results_text == "activity"){
            setOptinalResultsText((activityObject ? activityObject.name : ""))
          }
          else if(json.optinal_results_text == "region"){
            setOptinalResultsText((regionObject ? regionObject.name : ""))
          }
          else if(json.optinal_results_text == "time"){
            setOptinalResultsText((timeValue ? i18n.t("excursions." + timeValue) : ""))
          }else{
            setOptinalResultsText("")
          }
          setOptinalResults(json.no_results_found)
          setOptionalProducts(json.optinal_results)
        }
        
      }
      )
      .catch(error => console.log(error));
  }


  function createDataLayer(excursions){
    window.dataLayer.push({ ecommerce: null }); 
    	var new_products = []; 
      excursions && excursions.map((product) => {
        new_products.push(
          {
            'item_id': product.id,
            'item_name': product.title,
            'price': mkt_money(product),
            'item_brand': (product.agency ? product.agency?.name : "Outdoor Index"),
            'item_category': (product.zone && product.zone.name || ""),
            'item_variant': 'Excursion',
            'affiliation': 'Outdoor index',
            'quantity': '1'
          }
        ) // Clear the previous ecommerce object.
    })
    window.dataLayer.push({
      'event': "view_item_list",
      'ecommerce': {
        'item_list_id': "products_list",
        'item_list_name': "Products List",
        'items': new_products
       }
    });
  }
  return (
    <ThemeProvider theme={themeViewnia}>
      <section className="home-section">
        <section className="head-text">
          {loadingTitle || loading ? (
            <>
              <Skeleton 
                variant="text" 
                width="40%" 
                height={60} 
                className="skeleton-title"
                animation="wave"
                style={{ 
                  fontSize: '2.5rem',
                  lineHeight: '1.2',
                  transform: 'none',
                  margin: '0 auto',
                  marginBottom: '1.5rem',
                  textAlign: 'center',
                  
                }} 
              />
              <Skeleton 
                variant="text" 
                width="50%" 
                height={42} 
                className="skeleton-description"
                animation="wave"
                style={{ 
                  fontSize: '1.1rem',
                  lineHeight: '1.5',
                  transform: 'none',
                  margin: '0 auto',
                  marginBottom: '3em',
                  textAlign: 'center',
                }} 
              />
            </>
          ) : (
            <>
              <h1>{titleSearch || initialTitle}</h1>
              <p>{descriptionCategory || i18n.t("products_search.subtext")}</p>
            </>
          )}
        </section>

        <div className={"search-buttons"}>
          <FormControl  variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="grouped-select">{i18n.t("products_search.activities")}</InputLabel>
            <Select className="search-first-select" defaultValue="" id="grouped-select"
              value={activityValue}
              onChange={(e) => setActivityValue(e.target.value)}
              renderValue={(selected) => (
									<div className="select_activity">
									{activities.find(activity => activity.id == selected) && activities.find(activity => activity.id == selected).name} 
									</div>
								)}
              MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
              {activities && activities.map((activity) => {
                const labelId = `activities-label-${activity.id}`;
                return (
                  <MenuItem key={labelId} value={activity.id}>
                    <Radio color="primary" checked={activityValue == activity.id} />
                    {activity.name}
                  </MenuItem>
                );
               })}
            </Select>
          </FormControl>
          <FormControl  variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="grouped-select">{i18n.t("products_search.regions")}</InputLabel>
            <Select defaultValue="" id="grouped-select"
              value={regionValue}
              onChange={(e) => setRegionValue(e.target.value)}
              renderValue={(selected) => (
									<div className="select_region">
									{regions.find(region => region.id == selected) && regions.find(region => region.id == selected).name} 
									</div>
								)}
              MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
              {regions && regions.map((region) => {
                const labelId = `regions-label-${region.id}`;
                return (
                  <MenuItem key={labelId} value={region.id}>
                    <Radio color="primary" checked={regionValue == region.id} />
                    {region.name}
                  </MenuItem>
                );
               })}
            </Select>
          </FormControl>
          <FormControl  variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="grouped-select">{i18n.t("products_search.time")}</InputLabel>
            <Select defaultValue="" id="grouped-select"
              value={timeValue}
              onChange={(e) => setTimeValue(e.target.value)}
              renderValue={(selected) => (
									<div className="select_time">
									  {i18n.t("excursions." + selected)} 
									</div>
                  )}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                <MenuItem value={"half_day"}><Radio color="primary" checked={timeValue == "half_day"} />{i18n.t("excursions.half_day")}</MenuItem>
                <MenuItem value={"full_day"}><Radio color="primary" checked={timeValue == "full_day"} />{i18n.t("excursions.full_day")}</MenuItem>
                <MenuItem value={"multiple_days"}><Radio color="primary" checked={timeValue == "multiple_days"} />{i18n.t("excursions.multiple_days")}</MenuItem>
              </Select>
            </FormControl>
            <FormControl  variant="outlined" className={classes.formControl}>
            <Button variant="contained" color="secondary" onClick={() => search()}>
            <i className="fa fa-search" aria-hidden="true"></i>
        </Button>
            </FormControl>
          </div>
          
          {(props.home_products && !isSearch) && 
            <>
              <br /><br />
              <header><h2>{props.home_title}</h2></header>
                <Grid container spacing={2}>
                  {props.home_products && props.home_products.map((product) => {
                      const labelId = `products-label-${product.id}`;
                      return (
                        <Grid item xs={12} sm={6} md={3}>
                          <Product
                            key={labelId}
                            product={product}
                            image_format={"large"}
                          />
                        </Grid>
                      );
                  })}
                </Grid>      
            </>
          }

          <section className="products-section">
            <Grid container 
                  direction="row"
                  justify="space-between"
                  alignItems="center" 
                  spacing={2}>
              <Grid item xs={12} sm={8} className={classes.titleContainer}>
                {loading ? (
                  <Skeleton 
                    variant="text"
                    width="60%"
                    height={32}
                    animation="wave"
                  />
                ) : (
                  <h2>
                    {stringSearch ? 
                      <>{stringSearch} ({products.length})</> :
                      <>{i18n.t("products_search.all_adventures")} ({products.length})</>
                    }
                  </h2>
                )}
              </Grid>
              <Grid item xs={12} sm={4} className={`${classes.titleContainer} ${classes.skeletonContainer}`}>
                {loading ? (
                  <Skeleton 
                    variant="text"
                    width="120px"
                    height={32}
                    animation="wave"
                  />
                ) : (
                  <Button className="product-clear" onClick={() => clear()}>
                    {i18n.t("products_search.clear")}
                  </Button>
                )}
              </Grid>
            </Grid>

            {loading ? (
              <Grid container spacing={2}>
                {Array.from(new Array(8)).map((item, index) => (
                  <Grid key={"skeleton" + index} item xs={12} sm={6} md={3} className='posts'>
                    <Skeleton variant="rect" className="lazyload" height={(352)} animation="wave" />
                    <Skeleton variant="rect" className="lazyload info-slide product-skeleton-line" height={10} animation="wave" />
                    <Skeleton variant="rect" className="lazyload product-skeleton-line" height={10} animation="wave" />
                    <Skeleton variant="rect" className="lazyload product-skeleton-line" height={10} animation="wave" />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid container spacing={2}>
                {products && products.map((product) => {
                    let labelId = `products-label-${product.id}`;
                    return (
                      <Grid item xs={12} sm={6} md={3}>
                        <Product
                          key={labelId}
                          product={product}
                          image_format={"large"}
                        />
                      </Grid>
                    );
                })}
              </Grid>
            )}

            {/* Cargando más productos */}
            {isPageloading && hasMore && (
              <div style={{ textAlign: "center", margin: "20px" }}>
                <CircularProgress />
              </div>
            )}
          </section>

          {!loading && optional_products.length > 0 &&
            <section className="products-section">
              <Grid container direction="row"
                    justify="space-between"
                    alignItems="center" 
                    spacing={2}>
                  <Grid item xs={12} sm={8}>  
                    <h2>
                      {optinal_results_text ? 
                        <>{optinal_results_text} ({optional_products.length})</> :
                        <>{i18n.t("products_search.all_adventures")} ({optional_products.length})</>
                      }
                    </h2>
                  </Grid>
              </Grid>
              <Grid container spacing={2}>
                {optional_products && optional_products.map((product) => {
                    let labelId = `products-label-${product.id}`;
                    return (
                      <Grid item xs={12} sm={6} md={3}>
                        <Product
                          key={labelId}
                          product={product}
                          image_format={"large"}
                        />
                      </Grid>
                    );
                })}
              </Grid>
            </section>
          } 
      </section>
    </ThemeProvider>
  );
}

export default Products